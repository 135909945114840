////import Swal from "sweetalert2";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import HelperMixin from '../../../Shared/HelperMixin.vue'

export default {
    name: "Add",
    mixins: [HelperMixin],
    async created() {
        await this.CheckLoginStatus();
        await this.GetAllOffersType();
        


    },
    components: {
        flatPickr,
    },
    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
            return moment(date).format("MMMM Do YYYY");
        },
    },
    data() {
        return {
            Offices: [],


            dialogImageUrl: '',
            dialogVisible: false,
            activeNames: "",

            ApplicationsContactsList:[],

            ruleForm: {
                Id: 0,

                OffersTypeId: '',
                NameAr: '',
                NameEn: '',
                Outa: '',
                RewardQuota: '',
                RewardQuota1: '',
                Price: '',
                ExpiryValue: '',
                ExpiryType: '',

                PrepaidBypass: '',
                PostpaidBypass: '',
                Location: '',
                Sim: '',
                DeviceNetwork: '',
                BandTechnology: '',


                StartDate:'',
                EndDate:'',

                Notes: '',



            },
            rules: {

                OffersTypeId: this.$helper.RequiredInput('نو العرض '),
                NameAr: this.$helper.DynamicArabicEnterRequired('إسم العرض باللغة العربية '),
                NameEn: this.$helper.DynamicEnglishEnterRequired('إسم العرض باللغة الإنجليزية '),
                Outa: this.$helper.Required(),
                RewardQuota: this.$helper.Required(),
                RewardQuota1: this.$helper.Required(),
                ExpiryValue: this.$helper.Required(),
                ExpiryType: this.$helper.Required(),
                PrepaidBypass: this.$helper.Required(),
                PostpaidBypass: this.$helper.Required(),
                Location: this.$helper.Required(),
                Sim: this.$helper.Required(),
                DeviceNetwork: this.$helper.Required(),
                BandTechnology: this.$helper.Required(),
                
            },





        };
    },

    methods: {



        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {

                    //if (this.loginDetails.userType == 1) {
                    //    this.ruleForm.FacilitiesId = Number(0);
                    //    this.ruleForm.MunicipalitiesId = Number(0);
                    //}

                    this.ruleForm.Id = Number(0);
                    this.ruleForm.Outa = Number(this.ruleForm.Outa);
                    this.ruleForm.RewardQuota = Number(this.ruleForm.RewardQuota);
                    this.ruleForm.RewardQuota1 = Number(this.ruleForm.RewardQuota1);
                    this.ruleForm.Price = Number(this.ruleForm.Price);

                    if (this.ruleForm.PrepaidBypass) {
                        this.ruleForm.PrepaidBypass = Number(this.ruleForm.PrepaidBypass);
                    } else {
                        this.ruleForm.PrepaidBypass = Number(0);
                    }

                    if (this.ruleForm.ExpiryValue) {
                        this.ruleForm.ExpiryValue = Number(this.ruleForm.ExpiryValue);
                    } else {
                        this.ruleForm.PrepaidBypass = Number(0);
                    }

                    if (this.ruleForm.PostpaidBypass) {
                        this.ruleForm.PostpaidBypass = Number(this.ruleForm.PostpaidBypass);
                    } else {
                        this.ruleForm.PostpaidBypass = Number(0);
                    }


                    if (this.ruleForm.Location) {
                        this.ruleForm.Location = Number(this.ruleForm.Location);
                    } else {
                        this.ruleForm.Location = Number(0);
                    }


                    if (this.ruleForm.Sim) {
                        this.ruleForm.Sim = Number(this.ruleForm.Sim);
                    } else {
                        this.ruleForm.Sim = Number(0);
                    }

                    if (this.ruleForm.DeviceNetwork) {
                        this.ruleForm.DeviceNetwork = Number(this.ruleForm.DeviceNetwork);
                    } else {
                        this.ruleForm.DeviceNetwork = Number(0);
                    }

                    if (this.ruleForm.BandTechnology) {
                        this.ruleForm.BandTechnology = Number(this.ruleForm.BandTechnology);
                    } else {
                        this.ruleForm.BandTechnology = Number(0);
                    }

                    this.$blockUI.Start();
                    this.$http.AddOffers(this.ruleForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.resetForm(formName);
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الاضافة', err.response.data);
                        });
                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        resetForm(formName) {
            this.$refs[formName].resetFields();
        },


    },
};
