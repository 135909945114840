import Vue from 'vue';
import VueI18n from 'vue-i18n'
import VueRouter from 'vue-router';
import ElementUI from 'element-ui';
import Vuetify from 'vuetify'
import locale from 'element-ui/lib/locale/lang/en'
import BlockUIService from './Shared/BlockUIService.js';
import App from './App.vue';
import Layout from './components/Layout/Layout.vue';
import Login from './components/Login/Login.vue';
import Home from './components/Home/Home.vue';
import DataService from './Shared/DataService';
import Helper from './Shared/Helper';

import Users from './components/Users/Users.vue';
import Profile from './components/Users/EditUsersProfile/EditUsersProfile.vue'


import Martyrs from './components/Martyrs/Martyrs.vue'
import AddMartyrs from './components/Martyrs/Add/Add.vue'




import Offers from "./components/Offers/Offers.vue";
import AddOffers from "./components/Offers/Add/Add.vue";
import RequestsOffers from "./components/Offers/Requests/Requests.vue";




import OffersType from './components/Dictionaries/OffersType/OffersType.vue';






import VueEllipseProgress from 'vue-ellipse-progress';

Vue.use(VueEllipseProgress);

Vue.use(Vuetify);
Vue.use(VueI18n);
Vue.use(VueRouter);
Vue.use(ElementUI, { locale });

Vue.config.productionTip = false;

Vue.prototype.$http = DataService;
Vue.prototype.$blockUI = BlockUIService;
Vue.prototype.$helper = Helper;

export const eventBus = new Vue();

//const i18n = new VueI18n({
//    locale: 'ar', // set locale
//    messages, // set locale messages
//})

const router = new VueRouter({
    mode: "history",
    base: __dirname,
    linkActiveClass: "active",
    routes: [
        {
            path: "/Login",
            component: Login,
        },
        {
            path: "/",
            component: App,
            children: [
                {
                    path: "",
                    component: Layout,
                    children: [
                        { path: "", component: Home },


                        { path: "AddMartyrs", component: AddMartyrs },
                        { path: "Martyrs", component: Martyrs },



                        { path: "Offers", component: Offers },
                        { path: "AddOffers", component: AddOffers },
                        { path: "RequestsOffers", component: RequestsOffers },

                        { path: "OffersType", component: OffersType },

                        { path: "Users", component: Users },
                        { path: "Profile", component: Profile },

                        
                    ],
                },
            ],
        },
    ],
});

Vue.filter("toUpperCase", function (value) {
    if (!value) return "";
    return value.toUpperCase();
});

new Vue({
    router,
    render: (h) => {
        return h(App);
    },
}).$mount("#cpanel-management");
