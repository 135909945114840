<template>
    <div class="card h-100">
        <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between">
                <h6 class="mb-0">{{ChartHeaderOneLables}}</h6>
                <button type="button"
                        class="mb-0 btn btn-icon-only btn-rounded btn-outline-secondary ms-2 btn-sm d-flex align-items-center justify-content-center"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title
                        data-bs-original-title="See traffic channels">
                    <i class="fa fa-pie-chart text-danger" aria-hidden="true"></i>
                </button>
            </div>
        </div>
        <div class="p-3 pb-0 mt-4 card-body">
            <div class="row">
                <div class="col-4 text-start">
                    <div class="chart">
                        <canvas :id="chartId" class="chart-canvas" height="200"></canvas>
                    </div>
                </div>
                <div class="my-auto col-8 d-flex flex-wrap-reverse">
                    <span class="badge badge-md badge-dot me-4 d-block text-start" v-for="(item,index) in ChartOneLables" :key="item">
                        <i class="bg-info"></i>
                        <span class="text-xs text-dark"> {{item}} <span style="font-weight:bold">[{{ChartOneDate[index]}}]</span> </span>
                    </span>
                </div>
            </div>
            <br />
        </div>
        <!--<div class="p-3 pt-0 pb-0 card-footer d-flex align-items-center">
            <div class="w-60">
                <p class="text-sm" style="direction:ltr;text-align:right">
                    <b>[{{ChartOneDate[0]}}]</b> {{ChartOneLables[0]}}
                    <br />
                    <b>[{{ChartOneDate[1]}}]</b> {{ChartOneLables[1]}}
                    <br />
                    <b>[{{ChartOneDate[2]}}]</b> {{ChartOneLables[2]}}
                    <br />
                    <b>[{{ChartOneDate[3]}}]</b> {{ChartOneLables[3]}}
                </p>
            </div>
            <div class="w-40 text-end">
                <a class="mb-0 btn bg-light text-end" @click.prevent="href('/DailyChecks')" href="#">{{ChartHeaderOneButton}}</a>
            </div>
        </div>-->
    </div>
</template>

<script>
    import Chart from "chart.js/auto";
    export default {
        name: "ChannelsChartCard",
        data() {
            return {
                chartId: "chart-pie",
                ChartHeaderOneLables: this.$parent.ChartHeaderOneLables,
                ChartHeaderOneButton: this.$parent.ChartHeaderOneButton,
                ChartOneLables: this.$parent.ChartOneLables,
                ChartOneDate: this.$parent.ChartOneDate,
            };
        },
        methods: {
            href(url) {
                this.$router.push(url);
            }
        },

        mounted() {
            var pieChart = document.getElementById(this.chartId).getContext("2d");

            let chartStatus = Chart.getChart(this.id);
            if (chartStatus != undefined) {
                chartStatus.destroy();
            }



            // Pie chart
            new Chart(pieChart, {
                type: "pie",
                data: {
                    labels: this.$parent.ChartOneLables,
                    //labels: ["Facebook", "Direct", "Organic", "Referral"],
                    datasets: [
                        {
                            label: "���� ��������� ��� ������� ",
                            weight: 9,
                            cutout: 0,
                            tension: 0.9,
                            pointRadius: 2,
                            borderWidth: 2,
                            backgroundColor: [
                                "#FF6F91",
                                "#C0FDFB",
                                "#E84545",
                                "#7B8FA1",
                                "#FFC107",
                                "#FB5607",
                                "#FF006E",
                                "#6C63FF",
                                "#4ECDC4",
                                "#9B59B6",
                                "#5D3FD3",
                                "#F4D03F",
                                "#2980B9",
                                "#F8B195",
                                "#6BCAFA",
                                "#FF6B6B",
                                "#FFA500",
                                "#48C9B0",
                                "#8E44AD",
                                "#28B463",
                                "#8B008B",
                                "#00CED1"
                            ],
                            data: this.$parent.ChartOneDate,
                            //data: [15, 20, 12, 60],
                            fill: false,
                        },
                    ],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                    interaction: {
                        intersect: false,
                        mode: "index",
                    },
                    scales: {
                        y: {
                            grid: {
                                drawBorder: false,
                                display: false,
                                drawOnChartArea: false,
                                drawTicks: false,
                            },
                            ticks: {
                                display: false,
                            },
                        },
                        x: {
                            grid: {
                                drawBorder: false,
                                display: false,
                                drawOnChartArea: false,
                                drawTicks: false,
                            },
                            ticks: {
                                display: false,
                            },
                        },
                    },
                },
            });
        },
    };
</script>
