import axios from "axios";

 
export default {
    // ********************************************************************| Authintecations |***********************************************************

    //login(userName, passWord) {
    //    return axios.post(`/Security/loginUser?username=${userName}&password=${passWord}`);
    //},




    login(bodyObjeect) {
        return axios.post(`/Security/loginUser`, bodyObjeect);
    },

    AddMuntisbl(bodyObjeect) {  
        return axios.post(`/api/admin/Municipalities/Add`, bodyObjeect);
    },

    IsLoggedin() {
        return axios.get(`/Security/IsLoggedin`);
    },

    Logout() {
        return axios.post(`/Security/Logout`);
    },








    // ********************************************************************| Martyr |***********************************************************



    GetMartyr(pageNo, pageSize, nameorbarcode, DependenciesId, OfficesId, CitiesId, MartyrStatus, Lookover, ById) {
        return axios.get(`api/admin/Martyr/Filter?pageNo=${pageNo}&pagesize=${pageSize}
            &nameorbarcode=${nameorbarcode}&dependecyid=${DependenciesId}&officeid=${OfficesId}
            &cityid=${CitiesId}&status=${MartyrStatus}&lookover=${Lookover}&ById=${ById}`);
    },

    GetMartyrById(Id) {
        return axios.get(`api/admin/Martyr/GetMartyr?id=${Id}`);
    },









    // ********************************************************************| Martyr |***********************************************************
























    // ********************************************************************| Dictionaries |***********************************************************


    GetCitiesName() {
        return axios.get(`/api/admin/Martyr/CityFilter`);
    },


    GetOfficesName() {
        return axios.get(`/api/admin/Martyr/OfficeFilter`);
    },

    GetDependenciesName() {
        return axios.get(`/api/admin/Martyr/DependencyFilter`);
    },

    












    // OffersType
 

    GetOffersTypeName() {
        return axios.get(`/Api/Admin/Dictionaries/OffersType/GetAll`);
    },

    AddOffersType(bodyObject) {
        return axios.post(`api/admin/Dictionaries/OffersType/Add`, bodyObject);
    },

    EditOffersType(bodyObject) {
        return axios.post(`api/admin/Dictionaries/OffersType/Edit`, bodyObject);
    },

    DeleteOffersType(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/OffersType/Delete`);
    },

    // ********************************************************************| Dictionaries |***********************************************************











    // ********************************************************************| Offers |***********************************************************


    AddOffers(bodyObject) {
        return axios.post("api/admin/Offers/Add", bodyObject);
    },

    EditOffers(bodyObject) {
        return axios.post("api/admin/Offers/Edit", bodyObject);
    },

    GetOffers(pageNo, pageSize, Levels, ById, ByDate) {
        return axios.get(`api/admin/Offers/Get?pageNo=${pageNo}&pagesize=${pageSize}
            &Levels=${Levels}&ById=${ById}&ByDate=${ByDate}`);
    },

    GetOffersName(Levels) {
        return axios.get(`api/admin/Offers/GetAll?Levels=${Levels}`);
    },


    GetOffersById(code, Levels) {
        return axios.get(`api/admin/Applications/GetById?code=${code}&Levels=${Levels}`);
    },
        
    RejectOffers(bodyObject) {
        return axios.post("api/admin/Offers/Reject", bodyObject);
    },

    AcceptOffers(Id) {
        return axios.post(`api/admin/Offers/${Id}/Accept`);
    },

    DeleteOffers(Id) {
        return axios.post(`api/admin/Offers/${Id}/Delete`);
    },


    // ********************************************************************| Offers |***********************************************************












    EditWeeklyApplications(bodyObject) {
        return axios.post("api/admin/Applications/Weekly/Edit", bodyObject);
    },

    AddApplications(bodyObject) {
        return axios.post("api/admin/Applications/Add", bodyObject);
    },


    GetApplications(pageNo, pageSize, OffersTypeId, Municipalitiesid, FacilitiesId, DiseasesId, ById, ByDate) {
        return axios.get(`api/admin/Applications/Get?pageNo=${pageNo}&pagesize=${pageSize}
            &OffersTypeId=${OffersTypeId}&Municipalitiesid=${Municipalitiesid}&FacilitiesId=${FacilitiesId}&DiseasesId=${DiseasesId}
            &ById=${ById}&ByDate=${ByDate}`);
    },

    GetApplicationsById(code) {
        return axios.get(`api/admin/Applications/GetById?code=${code}`);
    },

    GetApplicationsWeekly(pageNo, pageSize, OffersTypeId, Municipalitiesid, FacilitiesId, DiseasesId, ByDate) {
        return axios.get(`api/admin/Applications/Weekly/Get?pageNo=${pageNo}&pagesize=${pageSize}
            &OffersTypeId=${OffersTypeId}&Municipalitiesid=${Municipalitiesid}&FacilitiesId=${FacilitiesId}&DiseasesId=${DiseasesId}
            &ByDate=${ByDate}`);
    },


    DeleteApplicationsWeekly(Id) {
        return axios.post(`api/admin/Applications/${Id}/Weekly/Delete`);
    },




    // Request
    GetApplicationsRequests(pageNo, pageSize, OffersTypeId, MunicipalitiesId, FacilitiesId, DiseasesId, ById, ByDate) {
        return axios.get(`api/admin/Applications/Requests/Get?pageNo=${pageNo}&pagesize=${pageSize}
            &OffersTypeId=${OffersTypeId}&MunicipalitiesId=${MunicipalitiesId}&FacilitiesId=${FacilitiesId}&DiseasesId=${DiseasesId}
            &ById=${ById}&ByDate=${ByDate}`);
    },

    GetRequestsById(code) {
        return axios.get(`api/admin/Applications/Requests/GetById?code=${code}`);
    },

    GetAllReuestInfo() {
        return axios.get(`api/admin/Applications/Requests/GetAll`);
    },


    AddRequest(bodyObject) {
        return axios.post("api/admin/Applications/Request/Add", bodyObject);
    },

    AddRequestApplications(bodyObject) {
        return axios.post("api/admin/Applications/Request/AddApplications", bodyObject);
    },

    GetApplicationsRequestsName(Id) {
        return axios.get(`api/admin/Applications/Requests/GetName?Id=${Id}`);
    },

    GetApplicationsRequest(pageNo, pageSize, OffersTypeId, Municipalitiesid, FacilitiesId, DiseasesId, ById, ByDate, RequestsId) {
        return axios.get(`api/admin/Applications/Requests/GetApplications?pageNo=${pageNo}&pagesize=${pageSize}
            &OffersTypeId=${OffersTypeId}&Municipalitiesid=${Municipalitiesid}&FacilitiesId=${FacilitiesId}&DiseasesId=${DiseasesId}
            &ById=${ById}&ByDate=${ByDate}&RequestsId=${RequestsId}`);
    },





    //ApplicationsAttachments
    DeleteApplicationsAttachments(Id) {
        return axios.post(`api/admin/Applications/${Id}/Attachments/Delete`);
    },

    AddApplicationsAttachments(bodyObject) {
        return axios.post("api/admin/Applications/Attachments/Add", bodyObject);
    },



    //Travel
    DeleteApplicationsTravel(Id) {
        return axios.post(`api/admin/Applications/${Id}/Travel/Delete`);
    },

    AddApplicationsTravel(bodyObject) {
        return axios.post("api/admin/Applications/Travel/Add", bodyObject);
    },

    GetApplicationsTravel(Id) {
        return axios.get(`api/admin/Applications/Travel/Get?Id=${Id}`);
    },



    //DiseasesHistory
    AddApplicationsDiseasesHistory(bodyObject) {
        return axios.post("api/admin/Applications/DiseasesHistory/Add", bodyObject);
    },

    GetDiseasesHistory(Id) {
        return axios.get(`api/admin/Applications/DiseasesHistory/Get?Id=${Id}`);
    },

    DeleteDiseasesHistory(Id) {
        return axios.post(`api/admin/Applications/${Id}/DiseasesHistory/Delete`);
    },


    //BlodTransfusion
    AddApplicationsBlodTransfusion(bodyObject) {
        return axios.post("api/admin/Applications/BlodTransfusion/Add", bodyObject);
    },

    GetApplicationsBlodTransfusion(Id) {
        return axios.get(`api/admin/Applications/BlodTransfusion/Get?Id=${Id}`);
    },

    DeleteApplicationsBlodTransfusion(Id) {
        return axios.post(`api/admin/Applications/${Id}/BlodTransfusion/Delete`);
    },



    //Hospitalizations
    AddApplicationsHospitalizations(bodyObject) {
        return axios.post("api/admin/Applications/Hospitalizations/Add", bodyObject);
    },

    GetApplicationsHospitalizations(Id) {
        return axios.get(`api/admin/Applications/Hospitalizations/Get?Id=${Id}`);
    },

    DeleteApplicationsHospitalizations(Id) {
        return axios.post(`api/admin/Applications/${Id}/Hospitalizations/Delete`);
    },



    //ApplicationsInfected
    AddApplicationsInfected(bodyObject) {
        return axios.post("api/admin/Applications/ApplicationsInfected/Add", bodyObject);
    },

    GetApplicationsInfected(Id) {
        return axios.get(`api/admin/Applications/ApplicationsInfected/Get?Id=${Id}`);
    },

    DeleteApplicationsInfected(Id) {
        return axios.post(`api/admin/Applications/${Id}/ApplicationsInfected/Delete`);
    },



    //ApplicationsContacts
    AddApplicationsContacts(bodyObject) {
        return axios.post("api/admin/Applications/ApplicationsContacts/Add", bodyObject);
    },

    GetApplicationsContacts(Id) {
        return axios.get(`api/admin/Applications/ApplicationsContacts/Get?Id=${Id}`);
    },

    DeleteApplicationsContacts(Id) {
        return axios.post(`api/admin/Applications/${Id}/ApplicationsContacts/Delete`);
    },


    //ApplicationsLab
    AddApplicationsLab(bodyObject) {
        return axios.post("api/admin/Applications/ApplicationsLab/Add", bodyObject);
    },

    GetApplicationsLab(Id) {
        return axios.get(`api/admin/Applications/ApplicationsLab/Get?Id=${Id}`);
    },

    DeleteApplicationsLab(Id) {
        return axios.post(`api/admin/Applications/${Id}/ApplicationsLab/Delete`);
    },



    //ApplicationsSymptoms
    AddApplicationsSymptoms(bodyObject) {
        return axios.post("api/admin/Applications/ApplicationsSymptoms/Add", bodyObject);
    },

    GetApplicationsSymptoms(Id) {
        return axios.get(`api/admin/Applications/ApplicationsSymptoms/Get?Id=${Id}`);
    },

    DeleteApplicationsSymptoms(Id) {
        return axios.post(`api/admin/Applications/${Id}/ApplicationsSymptoms/Delete`);
    },





    //ApplicationsOrgans
    AddApplicationsOrgans(bodyObject) {
        return axios.post("api/admin/Applications/ApplicationsOrgans/Add", bodyObject);
    },

    GetApplicationsOrgans(Id) {
        return axios.get(`api/admin/Applications/ApplicationsOrgans/Get?Id=${Id}`);
    },

    DeleteApplicationsOrgans(Id) {
        return axios.post(`api/admin/Applications/${Id}/ApplicationsOrgans/Delete`);
    },




    //ApplicationsAnimalsContacts
    AddApplicationsAnimalsContacts(bodyObject) {
        return axios.post("api/admin/Applications/ApplicationsAnimalsContacts/Add", bodyObject);
    },

    GetApplicationsAnimalsContacts(Id) {
        return axios.get(`api/admin/Applications/ApplicationsAnimalsContacts/Get?Id=${Id}`);
    },

    DeleteApplicationsAnimalsContacts(Id) {
        return axios.post(`api/admin/Applications/${Id}/ApplicationsAnimalsContacts/Delete`);
    },



















    // Lab
    AddLab(bodyObject) {
        return axios.post("api/admin/Applications/Lab/Add", bodyObject);
    },

    GetApplicationsLabRequest(pageNo, pageSize, OffersTypeId, Municipalitiesid, FacilitiesId, DiseasesId, ById, ByDate) {
        return axios.get(`api/admin/Lab/Get?pageNo=${pageNo}&pagesize=${pageSize}
            &OffersTypeId=${OffersTypeId}&Municipalitiesid=${Municipalitiesid}&FacilitiesId=${FacilitiesId}&DiseasesId=${DiseasesId}
            &ById=${ById}&ByDate=${ByDate}`)
    },

    AddLabResult(bodyObject) {
        return axios.post("api/admin/Lab/AddResult", bodyObject);
    },

    DeleteApplicationsLabRequest(Id) {
        return axios.post(`api/admin/Lab/${Id}/Delete`);
    },




    // ********************************************************************| Reports |***********************************************************


    GetMainReport(OffersTypeId, Municipalitiesid, FacilitiesId, DiseasesId, ById, ByDate, From, To) {
        return axios.get(`api/admin/Reports/Get?OffersTypeId=${OffersTypeId}&Municipalitiesid=${Municipalitiesid}&FacilitiesId=${FacilitiesId}&DiseasesId=${DiseasesId}
            &ById=${ById}&ByDate=${ByDate}&From=${From}&To=${To}`)
    },

    GetMainReportOne(OffersTypeId, Municipalitiesid, FacilitiesId, DiseasesId, ById, ByDate, From, To) {
        return axios.get(`api/admin/Reports/GetOne?OffersTypeId=${OffersTypeId}&Municipalitiesid=${Municipalitiesid}&FacilitiesId=${FacilitiesId}&DiseasesId=${DiseasesId}
            &ById=${ById}&ByDate=${ByDate}&From=${From}&To=${To}`)
    },










    // ********************************************************************| Users |***********************************************************
    GetUsers(pageNo, pageSize, Type, OfficeId) {
        return axios.get(`api/admin/User/Get?pageNo=${pageNo}&pagesize=${pageSize}&Type=${Type}&OfficeId=${OfficeId}`);
    },

    GetAllUsers() {
        return axios.get(`api/admin/User/GetAll`);
    },

    AddUser(bodyObject) {
        return axios.post("api/admin/User/Add", bodyObject);
    },

    EditUser(bodyObject) {
        return axios.post("api/admin/User/Edit", bodyObject);
    },

    ChangeStatusUser(Id) {
        return axios.post(`api/admin/User/${Id}/ChangeStatus`);
    },

    RestePassword(Id) {
        return axios.post(`api/admin/User/${Id}/RestePassword`);
    },

    DeleteUser(Id) {
        return axios.post(`api/admin/User/${Id}/Delete`);
    },

    UploadImage(bodyObject) {
        return axios.post("/Api/Admin/User/UploadImage", bodyObject);
    },

    EditUsersProfile(bodyObject) {
        return axios.post("/Api/Admin/User/EditUsersProfile", bodyObject);
    },

    ChangePassword(userPassword) {
        return axios.post(`/Api/Admin/User/ChangePassword`, userPassword);
    },











    // ********************************************************************| Dashboard Info |***********************************************************
    GetDashboardInfo() {
        return axios.get(`/api/admin/Reports/GetDashboardInfo`);
    },


























};
