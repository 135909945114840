import Swal from "sweetalert2";
import moment from "moment";
//import { excelParser } from "./excel-parser";
import HelperMixin from '../../../Shared/HelperMixin.vue'
export default {
    name: "AddUser",
    mixins: [HelperMixin],
    async created() {

        await this.CheckLoginStatus();
        this.GetInfo();
        await this.GetAllOffersType();



        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();
        this.dateNow = mm + "/" + dd + "/" + yyyy;


    },

    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            return moment(date).format("MMMM Do YYYY");
        },

        momentTime: function (date) {
            if (date === null) {
                return "لا يوجد";
            }
            return moment(date).format("DD/MM/YYYY");
        },

        momentYear: function (date) {
            if (date === null) {
                return "لا يوجد";
            }
            return moment(date).format("YYYY");
        },

    },


    data() {
        return {
            pageNo: 1,
            pageSize: 10,
            pages: 0,
            state: 0,


            Info: [],
            Offers: [],


            ById: '',
            ByDate: '',

            //Info
            SelectedItem: '',
            RejectedResone: '',

            RejectDiloag: false,





            ruleForm: {
                Id: 0,

                OffersTypeId: '',
                NameAr: '',
                NameEn: '',
                Outa: '',
                RewardQuota: '',
                RewardQuota1: '',
                Price: '',
                ExpiryValue: '',
                ExpiryType: '',

                PrepaidBypass: '',
                PostpaidBypass: '',
                Location: '',
                Sim: '',
                DeviceNetwork: '',
                BandTechnology: '',


                StartDate: '',
                EndDate: '',

                Notes: '',



            },
            rules: {

                OffersTypeId: this.$helper.RequiredInput('نو العرض '),
                NameAr: this.$helper.DynamicArabicEnterRequired('إسم العرض باللغة العربية '),
                NameEn: this.$helper.DynamicEnglishEnterRequired('إسم العرض باللغة الإنجليزية '),
                Outa: this.$helper.Required(),
                RewardQuota: this.$helper.Required(),
                RewardQuota1: this.$helper.Required(),
                ExpiryValue: this.$helper.Required(),
                ExpiryType: this.$helper.Required(),
                PrepaidBypass: this.$helper.Required(),
                PostpaidBypass: this.$helper.Required(),
                Location: this.$helper.Required(),
                Sim: this.$helper.Required(),
                DeviceNetwork: this.$helper.Required(),
                BandTechnology: this.$helper.Required(),

            },















        };
    },


    methods: {

        GetInfo(pageNo) {
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }

            if (this.byDate)
                this.byDate = this.ChangeDate(this.byDate);

            this.$blockUI.Start();
            this.$http
                .GetOffers(this.pageNo, this.pageSize, 1,this.DiseasesId, this.ById, this.ByDate)
                .then((response) => {
                    this.$blockUI.Stop();
                    this.Info = response.data.info;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },


        //Info
        ViewInfo(item) {
            this.SelectedItem = item;
            this.ruleForm.OffersTypeId = item.offersTypeId;
            this.ruleForm.NameAr = item.nameAr;
            this.ruleForm.NameEn = item.nameEn;
            this.ruleForm.Outa = item.outa;
            this.ruleForm.RewardQuota = item.rewardQuota;
            this.ruleForm.RewardQuota1 = item.rewardQuota1;
            this.ruleForm.Price = item.price;
            this.ruleForm.ExpiryValue = item.expiryValue;
            this.ruleForm.ExpiryType = item.expiryType;
            this.ruleForm.PrepaidBypass = item.prepaidBypass;
            this.ruleForm.PostpaidBypass = item.postpaidBypass;
            this.ruleForm.Location = item.location;
            this.ruleForm.Sim = item.sim;
            this.ruleForm.DeviceNetwork = item.deviceNetwork;
            this.ruleForm.BandTechnology = item.bandTechnology;
            this.ruleForm.StartDate = item.startDate;
            this.ruleForm.EndDate = item.endDate;
            this.ruleForm.Notes = item.notes;
            this.state = 1;
        },

        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {

                    this.ruleForm.Id = Number(0);
                    this.ruleForm.Outa = Number(this.ruleForm.Outa);
                    this.ruleForm.RewardQuota = Number(this.ruleForm.RewardQuota);
                    this.ruleForm.RewardQuota1 = Number(this.ruleForm.RewardQuota1);
                    this.ruleForm.Price = Number(this.ruleForm.Price);

                    if (this.ruleForm.PrepaidBypass) {
                        this.ruleForm.PrepaidBypass = Number(this.ruleForm.PrepaidBypass);
                    } else {
                        this.ruleForm.PrepaidBypass = Number(0);
                    }

                    if (this.ruleForm.ExpiryValue) {
                        this.ruleForm.ExpiryValue = Number(this.ruleForm.ExpiryValue);
                    } else {
                        this.ruleForm.PrepaidBypass = Number(0);
                    }

                    if (this.ruleForm.PostpaidBypass) {
                        this.ruleForm.PostpaidBypass = Number(this.ruleForm.PostpaidBypass);
                    } else {
                        this.ruleForm.PostpaidBypass = Number(0);
                    }


                    if (this.ruleForm.Location) {
                        this.ruleForm.Location = Number(this.ruleForm.Location);
                    } else {
                        this.ruleForm.Location = Number(0);
                    }


                    if (this.ruleForm.Sim) {
                        this.ruleForm.Sim = Number(this.ruleForm.Sim);
                    } else {
                        this.ruleForm.Sim = Number(0);
                    }

                    if (this.ruleForm.DeviceNetwork) {
                        this.ruleForm.DeviceNetwork = Number(this.ruleForm.DeviceNetwork);
                    } else {
                        this.ruleForm.DeviceNetwork = Number(0);
                    }

                    if (this.ruleForm.BandTechnology) {
                        this.ruleForm.BandTechnology = Number(this.ruleForm.BandTechnology);
                    } else {
                        this.ruleForm.BandTechnology = Number(0);
                    }

                    this.$blockUI.Start();
                    this.$http.EditOffers(this.ruleForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.resetForm(formName);
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الاضافة', err.response.data);
                        });
                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        resetForm(formName) {
            this.$refs[formName].resetFields();
        },






        AcceptRequest(Id) {
            Swal.fire({
                title: "هـل انت متأكد من الموافقة على العرض  ؟",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.AcceptOffers(Id)
                        .then((response) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                            this.GetInfo();
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', err.response.data);
                        });
                    return;
                }
            });
        },

        OpenRejectDiloag(item) {
            this.SelectedItem = item;
            this.RejectedResone = '';
            this.RejectDiloag = true;
        },

        RejectOffers() {
            Swal.fire({
                title: "هـل انت متأكد من رفض العرض   ؟",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {

                    let obj = {
                        Id: this.SelectedItem.id,
                        RejectedResone: this.RejectedResone
                    }

                    this.$blockUI.Start();
                    this.$http.RejectOffers(obj)
                        .then((response) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                            this.RejectDiloag = false;
                            this.GetInfo();
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', err.response.data);
                        });
                    return;
                }
            });
        },








        FilterByNumber() {
            this.Requests = [];
            let code = "";
            if (document.getElementById('selectInputPass') != null) {

                if (document.getElementById('selectInputPass').value == null || document.getElementById('selectInputPass').value == '')
                    return;

                code = document.getElementById('selectInputPass').value;
            }
            if (code.length <= 1)
                return;

            this.$blockUI.Start();
            this.$http.GetOffersById(code,1)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Offers = response.data.info;
                })
                .catch((err) => {
                    this.$blockUI.Stop();
                    console.error(err);
                });
        },

        Refresh() {
            this.CitiesId = '';
            this.MunicipalitiesId = '';
            this.FacilitiesId = '';
            this.DiseasesId = '';
            this.ById = '';
            this.ByDate = '';
            this.GetInfo();
        },

        Delete(Id) {
            Swal.fire({
                title: "هـل انت متأكد من عملية الحذف ؟",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.DeleteJobs(Id)
                        .then((response) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                            this.GetInfo();
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', err.response.data);
                        });
                    return;
                }
            });
        },









        




        Back() {
            this.state = 0;
            this.SelectedItem = '';
            this.GetInfo(this.pageNo);
        },

        ChangeDate(date) {
            if (date === null) {
                return "فارغ";
            }
            return moment(date).format("YYYY-MM-DD");
        },






    },
};
