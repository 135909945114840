/*import swal from "sweetalert";*/


import ReportsDoughnutChart from "../Charts/ReportsDoughnutChart.vue";
import GradientLineChart from "../Charts/GradientLineChart.vue";
import ThinBarChart from "../Charts/ThinBarChart.vue";
import RevenueChartCard from "../Charts/RevenueChartCard.vue";
import ChannelsChartCard from "../Charts/ChannelsChartCard.vue";
import SalesChartCard from "../Charts/SalesChartCard.vue";
//import ProgressLineChart from "../Charts/ProgressLineChart.vue";
//import ProgressDoughnutChart from "../Charts/ProgressDoughnutChart.vue";
import HelperMixin from '../../Shared/HelperMixin.vue'


export default {
    name: 'home',
    mixins: [HelperMixin],
    components: {
        ReportsDoughnutChart,
        GradientLineChart,
        ThinBarChart,
        RevenueChartCard,
        ChannelsChartCard,
        SalesChartCard,
        //ProgressLineChart,
        //ProgressDoughnutChart,
    },
    //prop: {
    //    chartTest: ['chartTest.Chart'],
    //},
    async created() {
        window.scrollTo(0, 0);
        

        //this.GetInfo();

        /*setInterval(() => this.GetInfo(), 60000);*/
        //this.GetInfo2();
        await this.CheckLoginStatus();

        console.log(this.loginDetails)

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();
        this.dateNow = mm + "/" + dd + "/" + yyyy;


        var date = new Date();
        var options = { weekday: 'long', locale: 'ar' };
        this.arabicDay = date.toLocaleDateString('ar', options);
    },
    data() {
        return {


            //Chart One
            ChartHeaderOneLables: 'بيانات احصائية ',
            ChartHeaderOneButton: 'عرض المزيد',
            ChartOneLables: ["Facebook", "Direct", "Organic", "Referral"],
            ChartOneDate: [15, 20, 12, 60],


            //ChartTwo
            ChartHeaderTwoLables: 'التقرير السنوي عن عمل النظام ',
            ChartTwoMonethLables: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],

            ChartTwoLableOne: "Facebook Ads",
            ChartTwoLineOne: [50, 100, 200, 190, 400, 350, 500, 450, 700],

            ChartTwoLableTwo: "Google Ads",
            ChartTwoLineTwo: [10, 30, 40, 120, 150, 220, 280, 250, 280],

            ChartTwoLableThree: "Facebook Ads",
            ChartTwoLineThree: [120, 240, 80, 320, 120, 220, 10, 220, 170],

            ChartTwoLableFour: "Facebook Ads",
            ChartTwoLineFour: [90, 200, 180, 170, 160, 130, 180, 170, 190],





            ChartBerMonth: null,
            ChartBerCompany: null,
            ChartBerDay: null,
            todayUsed: 0,
            pieChartCount: { number: 1, text: 'جهاز' },


            //chartIfno:
            //{
            //    labels: ['Jan', 'Fep', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            //    datasets: [
            //        {
            //                label: 'عدد مرات الغسيل',
            //                data: [50, 40, 300, 220, 500, 250, 400, 230, 500, 400, 230, 500],
            //        }
            //    ]
            //},


            //Chart: {
            //    labels: ['Nipro', ' B.Barun', 'Fresenius', 'Dia Life', 'Baxter'],
            //    datasets: {
            //        label: ['Nipro', ' B.Barun', 'Fresenius', 'Dia Life', 'Baxter'],
            //        data: [89, 20, 13, 32, 20],
            //    },
            //},

            //berDay: {
            //    labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            //    datasets: {
            //        label: 'عدد مرات الغسيل',
            //        data: [150, 230, 380, 220, 420, 200, 70, 500],
            //    },
            //},






            pageNo: 1,
            pageSize: 5,
            pages: 0,
            state: 0,

            arabicDay: '',
            dateNow: '',

            //loginDetails: null,
            Info: [],
            Info2: [],
            ElSteps: 0,
            ruleForm: {
                RegistryNumber: "",
                NationalId: "",
                Phone: "",
                Recaptcha: "",
            },
            Percentage: 0,
            loading: false,
            Voters: {},
            success: {
                confirmButtonText: "OK",
                type: "success",
                dangerouslyUseHTMLString: true,
                center: true,
            },
            error: {
                confirmButtonText: "OK",
                type: "error",
                dangerouslyUseHTMLString: true,
                center: true,
            },
            warning: {
                confirmButtonText: "OK",
                type: "warning",
                dangerouslyUseHTMLString: true,
                center: true,
            },
        };
    },
    methods: {

        //chartIfno: {
        //    labels: [
        //        'Jan',
        //        'Fep',
        //        'Mar',
        //        'Apr',
        //        'May',
        //        'Jun',
        //        'Jul',
        //        'Aug',
        //        'Sep',
        //        'Oct',
        //        'Nov',
        //        'Dec'
        //    ],
        //    datasets: [
        //        {
        //            label: 'عدد مرات الغسيل',
        //            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        //        }
        //    ]
        //},

        GetInfo() {
            //this.$blockUI.Start();
            this.$http.GetDashboardInfo()
                .then(response => {
                    //this.$blockUI.Stop();
                    //debugger

                    this.Info = response.data.info;


                    this.ChartOneLables = response.data.info.chartOneLabels;
                    this.ChartOneDate = response.data.info.chartOneData;

                    this.ChartTwoMonethLables = response.data.info.chartTwoMonethLables;

                    this.ChartTwoLableOne = response.data.info.chartTwoLableOne;
                    this.ChartTwoLineOne = response.data.info.chartTwoLineOne;

                    this.ChartTwoLableTwo = response.data.info.chartTwoLableTwo;
                    this.ChartTwoLineTwo = response.data.info.chartTwoLineTwo;

                    this.ChartTwoLableThree = response.data.info.chartTwoLableThree;
                    this.ChartTwoLineThree = response.data.info.chartTwoLineThree;

                    this.ChartTwoLableFour = response.data.info.chartTwoLableFour;
                    this.ChartTwoLineFour = response.data.info.chartTwoLineFour;



                })
                .catch(() => {
                    //this.$blockUI.Stop();
                });
        },





    }
}
